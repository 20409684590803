// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cafler-frontend-primary: mat.define-palette(mat.$indigo-palette);
$cafler-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cafler-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cafler-frontend-theme: mat.define-light-theme(
  (
    color: (
      primary: $cafler-frontend-primary,
      accent: $cafler-frontend-accent,
      warn: $cafler-frontend-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cafler-frontend-theme);

$roobert-font: 'Roobert', sans-serif;

body {
  font-family: $roobert-font;
}

.mat-typography {
  font-family: $roobert-font !important;
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab,
.mat-form-field {
  font-family: inherit;
}

.mat-dialog-container {
  font-family: $roobert-font !important;
}

* {
  font-family: $roobert-font !important;
}

hr {
  border-color: #c2c2c2 !important;
}

input,
.phone-input,
.white-small-box,
.white-big-box,
textarea,
.calendar-picker,
select {
  border: 1px solid var(--Neutrals-Dark-Grey, #c2c2c2) !important;
  cursor: text !important;

  &:focus {
    border: 1px solid var(--Dark, #180c2c) !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.cpointer {
  cursor: pointer;
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.h-500 {
  width: 500px !important;
}

.mb-10p {
  margin-bottom: 10px !important;
}
.mt-14p {
  margin-top: 14px !important;
}

.cvisibility {
  visibility: hidden;
  width: 0px;
  height: 0px;
  position: absolute;
}

.d-flex-1 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.mt-workers {
  margin-top: 145px;
  left: auto;
}

.fix-position {
  top: 60px;
  right: 220px;
}

select {
  -webkit-appearance: none;
}

textarea {
  padding: 15px;
}

input.grey-box {
  border: none !important;
  cursor: default;

  &:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

.prefix-select:focus {
  z-index: 10;
}

.phone-input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-Medium.otf') format('opentype');
  font-weight: 500; // Medium
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-MediumItalic.otf') format('opentype');
  font-weight: 500; // Medium
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-SemiBold.otf') format('opentype');
  font-weight: 600; // SemiBold
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-SemiBoldItalic.otf') format('opentype');
  font-weight: 600; // SemiBold
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-Heavy.otf') format('opentype');
  font-weight: 800; // Heavy
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-HeavyItalic.otf') format('opentype');
  font-weight: 800; // Heavy
  font-style: italic;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-Light.otf') format('opentype');
  font-weight: 300; // Light
  font-style: normal;
}

@font-face {
  font-family: 'Roobert';
  src: url('assets/fonts/Roobert-LightItalic.otf') format('opentype');
  font-weight: 300; // Light
  font-style: italic;
}

$font-family-base: 'Roobert', sans-serif;

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
input,
label,
select,
textarea,
span,
div,
section {
  font-family: $font-family-base;
}

h4 {
  color: var(--Dark, #180c2c);
  font-feature-settings:
    'clig' off,
    'liga' off;

  /* text-base-m/medium */
  font-family: Roobert;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  margin: 0;
}

// Maps CSS
.marker-label {
  position: relative;
  bottom: 40px;
  background-color: white;
  padding: 2px 8px 2px 8px;
  border-radius: 6px;
  font-weight: bold;
  font-family: $font-family-base;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.marker-label-active {
  position: relative;
  bottom: 40px;
  background-color: white;
  padding: 2px 8px 2px 8px;
  border: 1px solid black;
  border-radius: 6px;
  font-weight: bold;
  font-family: $font-family-base;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.card {
  display: flex;
  height: 108px;
  padding: 18.462px !important;
  flex-direction: column;
  align-items: flex-start;
  gap: 13.538px;
  flex: 1 0 0;
  border-radius: 12.308px;
  border: 1.231px solid var(--Neutrals-Middle-Grey, rgba(225, 225, 225, 0.6));
  background: var(--Neutrals-White, #fff);

  h5 {
    color: var(--gray-500, #71717a);
    font-feature-settings:
      'clig' off,
      'liga' off;

    /* text-xs/medium */
    font-family: Roobert;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    margin-bottom: 13.538px;
  }

  h2 {
    color: var(--Dark, #180c2c);
    font-family: Roobert;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .card-body {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.search-bar,
.buscar-matricula {
  margin: 30px 0;
  height: 44px;
}

.navbar-services {
  color: #6c6c6c;

  .underlined,
  .active {
    color: black !important;
  }
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

/* Importing Bootstrap SCSS file. */
@import './node_modules/bootstrap/scss/bootstrap';

/* Importing Datepicker SCSS file. */
@import 'node_modules/ngx-bootstrap/datepicker/bs-datepicker';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.custom-financing-dialog-class {
  width: 626px;
  height: auto;
  top: 322px;
  padding: 25px;
  gap: 26px;
  border-radius: 10px;
  max-width: 90vw;
  max-height: 90vh;
  position: absolute !important;
  .mat-dialog-container {
    // Para implementar el gap entre elementos hijos
    & > * {
      margin-bottom: 26px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

// Media query para responsividad
@media (max-width: 768px) {
  .custom-financing-dialog-class {
    .mat-dialog-container {
      width: 90vw;
      max-width: 626px;
      min-height: auto;
    }
  }
}
